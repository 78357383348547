import React from 'react';
import { Document, Page, View, Image, Text } from '@react-pdf/renderer';

import remoteLogo from '../assets/remoteLogo.png';
import { Client, EurService } from '../types';
import { RenderStyles } from './RenderStyles';
import { add30Days, formatDatum } from '../utils';

// Register font
// Font.register({
//   family: 'Helvetica',
//   src: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap',
// });

interface RenderProps {
  brojRacuna: string;
  datum: string;
  client: Client;
  services: EurService[];
  tecaj: string;
  totalSum: number;
  totalSumEur: string;
}

export const Render = ({ brojRacuna, datum, client, services, tecaj, totalSum, totalSumEur }: RenderProps) => {
  return (
    <Document title="Remote d.o.o. Invoice" author="Ante Kalajzic">
      <Page style={RenderStyles.page}>
        <View style={RenderStyles.justifyBetween}>
          <View style={RenderStyles.flexCol}>
            <Image src={remoteLogo} style={RenderStyles.image} />
          </View>
          <View style={{ ...RenderStyles.flexCol, alignItems: 'flex-end' }}>
            <Text style={RenderStyles.brojRacuna}>R racun br. {brojRacuna}</Text>
            <Text style={{ textAlign: 'right' }}>Datum racuna: {formatDatum(datum)}</Text>
            <Text style={{ textAlign: 'right' }}>Datum isporuke: {formatDatum(datum)}</Text>
            <Text style={{ textAlign: 'right' }}>Rok placanja: {add30Days(datum)}</Text>
          </View>
        </View>

        <View style={{ ...RenderStyles.justifyBetween, ...RenderStyles.mt28 }}>
          <View style={RenderStyles.flexCol}>
            <Text style={{ ...RenderStyles.label, marginTop: '8px' }}>Racun izdao</Text>
            <Text style={RenderStyles.companyName}>REMOTE d.o.o.</Text>
            <Text>Cetvrt Z.Drazojevica 15, 21310 Omis</Text>
            <Text style={{ ...RenderStyles.label, marginTop: '8px' }}>OIB</Text>
            <Text>17382814609</Text>
          </View>
          <View style={RenderStyles.buyer}>
            <Text style={RenderStyles.label}>Kupac</Text>
            <Text style={RenderStyles.companyName}>{client.name}</Text>
            <Text>{client.address1}</Text>
            <Text>{client.address2}</Text>
            <Text>{client.address3}</Text>
          </View>
        </View>

        <View style={RenderStyles.mt28}>
          <View style={{ ...RenderStyles.flexRow, ...RenderStyles.tableHeader }}>
            <Text style={{ width: '8%' }}>R.br.</Text>
            <Text style={{ width: '48%' }}>Opis proizvoda/usluge</Text>
            <Text style={{ width: '7%' }}>Jed.</Text>
            <Text style={{ width: '7%' }}>Kol.</Text>
            <Text style={{ width: '14%' }}>Cijena</Text>
            <Text style={{ width: '16%' }}>Iznos stavke</Text>
          </View>
          {services.map((service, index) => (
            <React.Fragment key={index}>
              <View key={index} style={{ ...RenderStyles.flexRow, ...RenderStyles.tableUsdLine }}>
                <Text style={{ width: '8%' }}>{index + 1}</Text>
                <Text style={{ width: '48%' }}>{service.name}</Text>
                <Text style={{ width: '7%' }}>{service.serviceType}</Text>
                <Text style={{ width: '7%' }}>{service.kolicina}</Text>
                <Text style={{ width: '14%' }}>
                  {service.basePrice} {client.currency}
                </Text>
                <Text style={{ width: '16%' }}>
                  {(service.kolicina || 1) * service.basePrice} {client.currency}
                </Text>
              </View>
              {client.currency !== 'EUR' && (
                <View
                  key={index}
                  style={{
                    ...RenderStyles.flexRow,
                    ...RenderStyles.tableEurLine,
                    ...RenderStyles.borderBottom,
                    ...RenderStyles.gray,
                  }}
                >
                  <Text style={{ width: '70%' }}></Text>
                  <Text style={{ width: '14%' }}>({service.eurPrice} €)</Text>
                  <Text style={{ width: '16%' }}>({service.eurTotalPrice} €)</Text>
                </View>
              )}
            </React.Fragment>
          ))}
          <View style={{ ...RenderStyles.flexRow, ...RenderStyles.tableTotal }}>
            <Text style={{ width: '8%' }}></Text>
            <Text style={{ width: '48%' }}></Text>
            <Text style={{ width: '21%' }}>Ukupan iznos naplate</Text>
            <Text style={{ width: '7%' }}></Text>
            <Text style={{ fontWeight: 700, width: '16%' }}>
              {totalSum} {client.currency}
            </Text>
          </View>
          {client.currency !== 'EUR' && (
            <View style={{ ...RenderStyles.flexRow, ...RenderStyles.tableEurLine, ...RenderStyles.gray }}>
              <Text style={{ width: '84%' }}></Text>
              <Text style={{ fontWeight: 700, width: '16%' }}>({totalSumEur} €)</Text>
            </View>
          )}
        </View>

        <View style={RenderStyles.mt28}>
          <View style={{ marginTop: '8px' }}>
            <Text style={RenderStyles.label}>Nacin placanja</Text>
            <Text>Transakcijski racun</Text>
          </View>
          <View style={{ marginTop: '8px' }}>
            <Text style={RenderStyles.label}>Operater / Racun izdao</Text>
            <Text>Ante Kalajzic</Text>
          </View>
          <View style={{ marginTop: '8px' }}>
            <Text style={RenderStyles.label}>Napomene</Text>
            <Text>Preracunato po srednjem tecaju ECB-a (1 EUR = {tecaj} USD)</Text>
            <Text>Prijenos porezne obveze prema Zakonu o PDV-u čl. 17 (Reverse charge Law on Vat Article 17).</Text>
          </View>
          <View style={{ marginTop: '8px' }}>
            <Text style={RenderStyles.label}>Podaci za uplatu</Text>
            <Text>SWIFT IBAN CMFGUS33 + TransferWise FBO Remote d.o.o. 026073 poziv na broj 2025</Text>
          </View>
        </View>

        <View style={RenderStyles.footer}>
          <View style={RenderStyles.footerColumns}>
            <View style={RenderStyles.flexCol}>
              <Text>Pravni podaci</Text>
              <Text style={RenderStyles.gray}>OIB: 17382814609</Text>
              <Text style={RenderStyles.gray}>PDV ID: HR17382814609</Text>
              <Text style={RenderStyles.gray}>IBAN: CMFGUS33 + TransferWise FBO Remote d.o.o. 026073</Text>
            </View>
            <View style={RenderStyles.flexCol}>
              <Text>Kontakti</Text>
              <Text style={RenderStyles.gray}>Telefon: +385921003676</Text>
              <Text style={RenderStyles.gray}>Email: ante@remote.hr</Text>
            </View>
          </View>
          <View>
            <Text style={RenderStyles.gray}>
              Drustvo je upisano u sudski registar Trgovackog suda u Splitu pod brojem 060338513.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
