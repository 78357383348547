import { Service } from '../types';

const services = [
  {
    id: 1,
    serviceType: 'usl',
    name: 'Development Consultancy',
    basePrice: 6250,
    baseCurrency: 'USD',
  },
  {
    id: 2,
    serviceType: 'usl',
    name: 'Front-end Development',
    basePrice: 9100,
    baseCurrency: 'USD',
  },
  {
    id: 3,
    serviceType: 'kom',
    name: 'Sat Veles - bijeli',
    basePrice: 480,
    baseCurrency: 'EUR',
  },
  {
    id: 4,
    serviceType: 'kom',
    name: 'Sat Veles - crni',
    basePrice: 480,
    baseCurrency: 'EUR',
  },
];

export default services as Service[];
